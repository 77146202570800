// ************************************************************************
// * DELL PROPRIETARY INFORMATION
// * 
// * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
// * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
// * provided by or on behalf of Dell Inc. or its subsidiaries.
// *  
// * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
// *  
// * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
// * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
// * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
// * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
// * DERIVATIVES.
// ************************************************************************

.logo {
  height: 40px; 
}

.info-icon{
	font-size: 19px;
}

.header-white {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	background: #ffffff;
	z-index: 10000;
	height: 56px;
	-webkit-transition: height 0.3s;
	-moz-transition: height 0.3s;
	transition: height 0.3s;
}

.header-blue {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	background: #0672cb;
	z-index: 1000;
	height: 56px;
	-webkit-transition: height 0.3s;
	-moz-transition: height 0.3s;
	transition: height 0.3s;
	display: flex;
	-moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

	@media (min-width: 1853px) {
		width: 75%;
	  }
}

.main__container{
	position: fixed;
	z-index: 10000000;
	width: 100%;
}

.button-class{
	height: 56px;
	justify-content: center;
	display: flex;
	left: calc(50% - 96px);
	position: absolute;

	@media screen and (max-width: 1000px) {
		left: 45%;
	}
}

.dds__icon--alert-info-cir{
	color: #A4B8CD;
	align-items: center;
	font-size: 22px;
}


.dds__popover__content{  
	width: 350px;
	margin: 0;
}

.dds__popover__header{
	margin-bottom: 0;
}

.popover-alignment-base{
	margin-top: 10px;
	height: fit-content;	
	padding: 0;
	border: none;
}
  
.popover-alignment-base:hover{
	cursor: pointer;
	background-color: transparent !important;
  }

.fade-in {
	margin-top: 0.5%;
	animation: fadeIn ease-in-out 0.5s forwards;
  }
  
  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1;
	}
  }

 
.connect_btn{
	color: #0672cb;
}

.dell-logo{
	width: 182px;
	height: 56px;
	padding-left: 10px;
	justify-content: center;
	display: flex;
}

.header-title-black {
	color: #0E0E0E;
	margin-top: 13.1px;
	justify-content: center;
}

.header-title-white {
	color: #ffffff;
	margin-top: 13.1px;
	justify-content: center;
	width: 200px;
}

.hidden{
	display: none;
}