// ************************************************************************
// * DELL PROPRIETARY INFORMATION
// *
// * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
// * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
// * provided by or on behalf of Dell Inc. or its subsidiaries.
// *
// * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
// *
// * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
// * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
// * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
// * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
// * DERIVATIVES.
// ************************************************************************

@use "@dds/components/src/scss/dds-reboot.scss";
@use "@dds/components/src/scss/dds-fonts.scss";
@use "@dds/components/src/scss/dds-icons.scss";
@use "@dds/components/src/scss/dds-helpers.scss";
@use "@dds/components/src/scss/dds-main.scss";

body > #root {
  height: 100vh;
  // minimum width of 1583px is choosen as it is breakpoint for xl screen size from dds
  // This is to reduce the stretch of page when the screen size is greater than 1583px
  @media (min-width: 1583px) {
    width: 75%;
    margin: 0 auto;
  }
  a {
    text-decoration: none;
  }
}
