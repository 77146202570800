// ************************************************************************
// * DELL PROPRIETARY INFORMATION
// *
// * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
// * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
// * provided by or on behalf of Dell Inc. or its subsidiaries.
// *
// * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
// *
// * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
// * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
// * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
// * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
// * DERIVATIVES.
// ************************************************************************

.logo {
  margin-left: 20px;
  font-size: 58px;
  color: #0672cb;
}

.header {
  padding-right: 5px;
}

.profile-image {
  margin-bottom: 10px;
  margin-left: 7px;
  font-size: 40px;
  color: #636363;
}

.action-menu-profile-name {
  text-align: center;
  padding-top: 10px;
}

.header-title {
  font-size: 1.4rem;
  font-weight: 400;
  color: #0E0E0E;
  width: max-content;
  height: fit-content;
  padding-top: 13.1px;
  margin-left: 10px;
}

.header__container {
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  background-color: #ffffff;
  .routes {
    .link {
      text-decoration: unset;
      color: unset;
      outline: none;
    }
    .link:active{
      outline: none;
    }
    .active__link {
      color: #0672cb;
    }
    .active__link:focus{
      outline: none;
    }
    .link__icon {
      margin-left: 0.5rem;
      color: #0672cb;
      vertical-align: middle;
    }
  }
  .button.dds__button--editorial-light.dds__button--primary, .dds__button--editorial-light.dds__button {
    background-color: #ffffff;
  }
}


@media screen and (max-width: 768px) {
  .button {
    margin-right: 20px;
  }
}

.button {
  display: flex;
  padding: 0 10px;
  margin-right: 5px;
}

.username {
  color: black;
  width: max-content;
  align-items: center;
}

.loggedin-user {
  display: flex;
  margin-top: 10px;
  align-items: center;
  gap: 10px;

  .dds__button .dds__icon {
    font-size: 1.1rem;
  }
}

.profile-image {
  margin-bottom: 10px;
  color: #636363;
  font-size: 18px !important;
}

.profile-image-second {
  margin-bottom: 10px;
  margin-top: 5px;
  color: #636363;
  font-size: 18px !important;
}

.custom-icon-pop-up-arrow-corner {
  margin-bottom: 2px;
}

.resize-icon--chevron-down {
  width: 18px !important;
}

.dell-logo-auth{
  height: 56px;
  width: 214px;
  padding-left: 10px;
}