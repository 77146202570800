// ************************************************************************
// * DELL PROPRIETARY INFORMATION
// *
// * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
// * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
// * provided by or on behalf of Dell Inc. or its subsidiaries.
// *
// * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
// *
// * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
// * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
// * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
// * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
// * DERIVATIVES.
// ************************************************************************

.footer {
  padding: 1.5rem;
  background: #f0f0f0;
  max-width: 100%;
  .address {
    line-height: 2rem;
  }
}

.footer-container {
  padding: 0;
  .links {
    a {
      p {
        color: black;
      }
      p:hover {
        text-decoration: underline;
      }
    }
  }
  .footer-content {
    max-width: 85%;
  }
  a {
    color: #000000;
    text-decoration: none;
  }
}

.footer-icon {
  font-size: 21.33px;
}

#language-picker {
  margin-left: 0px;
  align-items: center;
  gap: 8px;
}

@media screen and (min-width: 1440px) {
  .links {
    padding-left: 40%;
  }
}

@media screen and (width: 1024px) {
  .links {
    padding-left: 20%;
  }
}
